import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus';
//import installElementPlus from './plugins/element'
import 'element-plus/dist/index.css';
import './assets/css/icon.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import * as echarts from 'echarts'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import { getCurrentInstance } from 'vue';
import md5 from "js-md5" //导入
import vueSeamlessScroll from 'vue-seamless-scroll' // 循环滚动






const app = createApp(App)
app.config.globalProperties.$md5 = md5
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.echarts = echarts
app.use(ElementPlus, {
    locale: zhCn,
})
app.use(router).use(ElementPlus).use(vueSeamlessScroll).use(createPinia()).mount('#app')

// const app = createApp(App)
// installElementPlus(app)
// app.use(createPinia())
//     .use(router)
//     .mount('#app')