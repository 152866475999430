import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import {
    ElMessage
} from "element-plus";
const routes = [{
    path: '/',
    redirect: '/fapiao'
}, {
    path: "/",
    name: "Home",
    component: Home,
    children: [{
        path: '/fapiao',
        name: 'fapiao',
        meta: {
            title: '发票信息查询'
        },
        component: () =>
            import ('../views/fapiao.vue')
    }, {
        path: '/fapiaoupload',
        name: 'fapiaoupload',
        meta: {
            title: '发票列表上传'
        },
        component: () =>
            import ('../views/fapiaoupload.vue')
    }, {
        path: '/yinzhang',
        name: 'yinzhang',
        meta: {
            title: '印章领用查询'
        },
        component: () =>
            import ('../views/yinzhang.vue')
    }, {
        path: '/userlist',
        name: 'userlist',
        meta: {
            title: '用户管理'
        },
        component: () =>
            import ('../views/userlist.vue')
    }, {
        path: '/getpersonlist',
        name: 'getpersonlist',
        meta: {
            title: '领用人管理'
        },
        component: () =>
            import ('../views/getpersonlist.vue')
    }, {
        path: '/yinzhangcompany',
        name: 'yinzhangcompany',
        meta: {
            title: '印章公司管理'
        },
        component: () =>
            import ('../views/yinzhangcompany.vue')
    }, ]
}, {
    path: "/login",
    name: "Login",
    meta: {
        title: '登录'
    },
    component: () =>
        import ("../views/Login.vue")
}]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})
router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} | BIOBASE发票印章系统`;
    const role = sessionStorage.getItem('token');
    if (!role && to.path !== '/login') {
        console.log("第一" + role)
        next('/login');
        return
    } else if (to.meta.permission) {
        // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
        role === 'admin' ?
            next() :
            next('/403');
        console.log("第二" + role)
        return
    } else {
        console.log("第三" + role)
        console.log(to.path)
        next();
        return
    }
});
export default router