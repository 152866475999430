<template>
    <div class="tags" v-if="tags.show">
        <ul>
            <li class="tags-li" v-for="(item,index) in tags.list" :class="{'active': isActive(item.path)}" :key="index">
                <router-link :to="item.path" class="tags-li-title">{{item.title}}</router-link>
                <span class="tags-li-icon" @click="closeTags(index)">
                    <el-icon color=""><Close /></el-icon>
                </span>
            </li>
        </ul>
        <div class="tags-close-box">
            <el-dropdown @command="handleTags">
                <el-button size="small" type="primary">
                    标签选项
                    <el-icon><ArrowDown /></el-icon>
                    <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu size="small">
                        <el-dropdown-item command="other">关闭其他</el-dropdown-item>
                        <el-dropdown-item command="all">关闭所有</el-dropdown-item>
                        <el-dropdown-item command="loginout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { useTagsStore } from '../store/tags'
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
export default {
    setup() {
        const route = useRoute();
        const router = useRouter();
        const isActive = (path) => {
            return path === route.fullPath;
        };

        const tags = useTagsStore();
        // 关闭单个标签
        const closeTags = (index) => {
            const delItem = tags.list[index];
            tags.delTagsItem(index);
            const item = tags.list[index] ? tags.list[index] : tags.list[index - 1];
            if (item) {
                delItem.path === route.fullPath && router.push(item.path);
            } else {
                router.push("/");
            }
        };

        // 设置标签
        const setTags = (route) => {
            const isExist = tags.list.some((item) => {
                return item.path === route.fullPath;
            });
            if (!isExist) {
                if (tags.list.length >= 8) tags.delTagsItem(0);
                tags.setTagsItem({
                    name: route.name,
                    title: route.meta.title,
                    path: route.fullPath,
                });
            }
        };
        setTags(route);
        onBeforeRouteUpdate((to) => {
            setTags(to);
        });

        // 关闭全部标签
        const closeAll = () => {
            tags.clearTags();
            router.push("/");
        };
        // 关闭其他标签
        const closeOther = () => {
            const curItem = tags.list.filter((item) => {
                return item.path === route.fullPath;
            });
            tags.closeTagsOther(curItem);
        };
        //  const handleCommand = (command) => {
        //     if (command == "loginout") {
        //         sessionStorage.removeItem("ms_username");
        //         router.push("/login");
        //     } else if (command == "user") {
        //         router.push("/user");
        //     }
        // };
        const gologin=()=>{
             sessionStorage.removeItem("token");
             sessionStorage.removeItem("user_id");
             sessionStorage.removeItem("user_type");
             sessionStorage.removeItem("user_relname");
                router.push("/login");
        }
        const handleTags = (command) => {
            //command === "other" ? closeOther() : closeAll();
            if(command=="other"){
                closeOther();
            }
            else if(command=="all"){
                closeAll();
            }
            else if(command="loginout"){
                gologin();
            }
        };

        // 关闭当前页面的标签页
        // tags.closeCurrentTag({
        //     $router: router,
        //     $route: route
        // });

        return {
            isActive,
            tags,
            closeTags,
            handleTags,
        };
    },
};
</script>


<style>
.tags {
    position: relative;
    height: 30px;
    overflow: hidden;
    background: #fff;
    padding-right: 120px;
    box-shadow: 0 5px 10px #ddd;
}

.tags ul {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color:#d2e9ec;
}

.tags-li {
    float: left;
    margin: 3px 5px 2px 3px;
    border-radius: 3px;
    font-size: 12px;
    overflow: hidden;
    cursor: pointer;
    height: 23px;
    line-height: 23px;
    border: 1px solid #3779e9;
    background: #3779e9;
    padding: 0 5px 0 12px;
    vertical-align: middle;
    color: #3779e9;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.tags-li:not(.active):hover {
    background: #d18d3f;
     border: 1px solid #d18d3f;
    color: #ddd;
}
.tags-li.active {
    border: 1px solid #d18d3f;
    background-color: #d18d3f;
}
.tags-li.active {
    color: #fff;
}

.tags-li-title {
    float: left;
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 5px;
    color: #fff;
}

.tags-li.active .tags-li-title {
    color: #fff;
}

.tags-close-box {
    position: absolute;
    right: 0;
    top: 0;
    box-sizing: border-box;
    padding-top: 1px;
    text-align: center;
    width: 110px;
    height: 30px;
    background: #fff;
    box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);
    z-index: 10;
}
</style>
