<template>
    <div class="sidebar">
        <el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="sidebar.collapse" background-color="#272d36"
            text-color="#fff" active-text-color="#fff" unique-opened router>
            <template v-for="item in items">
                <template v-if="item.subs">
                    <el-sub-menu :index="item.index" :key="item.index">
                        <template #title>
                            <!-- <img :src="item.icon" alt=""> -->
                            <el-icon><component class="xxx" :is="item.icon"></component></el-icon>
                            <span>{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.subs">
                            <el-sub-menu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                                <template #title>{{ subItem.title }}</template>
                                <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="i" :index="threeItem.index">
                                    {{ threeItem.title }}</el-menu-item>
                            </el-sub-menu>
                            <el-menu-item v-else :index="subItem.index" :key="subItem.index">{{ subItem.title }}
                            </el-menu-item>
                        </template>
                    </el-sub-menu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.index" :key="item.index">
                        <el-icon>
                            <component class="xxx" :is="item.icon"></component>
                        </el-icon>
                        <template #title>{{ item.title }}</template>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
import { computed, reactive,ref } from "vue";
import { useSidebarStore } from '../store/sidebar'
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
export default {
    setup() {
        const router = useRouter();
        const items=ref([])
        //用户类型1，管理员菜单
        const item1 = [
            {
                icon: "Tickets",
                index: "2",
                title: "发票管理",
                subs: [
                    {
                        index: "/fapiao",
                        title: "发票信息查询",
                    },
                    {
                        index: "/fapiaoupload",
                        title: "发票列表上传",
                    }
                ],
            },
             {
                icon: "Stamp",
                index: "3",
                title: "印章管理",
                subs: [
                    {
                        index: "/yinzhang",
                        title: "印章领用查询",
                    }
                ],
            },
            {
                icon: "User",
                index: "4",
                title: "系统管理",
                subs: [
                    {
                        index: "/userlist",
                        title: "用户管理",
                    },
                    {
                        index: "/getpersonlist",
                        title: "领用人管理",
                    }
                    ,
                    {
                        index: "/yinzhangcompany",
                        title: "印章公司管理",
                    }
                ],
            },
            
        ];
       //用户类型3，只看发票
        const item3 = [
        {
                icon: "Tickets",
                index: "3",
                title: "发票管理",
                subs: [
                    {
                        index: "/fapiao",
                        title: "发票信息查询",
                    },
                    {
                        index: "/fapiaoupload",
                        title: "发票列表上传",
                    }
                ],
            },
        ];
            //用户类型4，只看印章
        const item4 = [
         {
                icon: "Stamp",
                index: "4",
                title: "印章管理",
                subs: [
                    {
                        index: "/yinzhang",
                        title: "印章领用查询",
                    }
                ],
            },
        ];
           //用户类型2，发票印章都看
        const item2 = [
         {
                icon: "Tickets",
                index: "2",
                title: "发票管理",
                subs: [
                    {
                        index: "/fapiao",
                        title: "发票信息查询",
                    }
                ],
            },
             {
                icon: "Stamp",
                index: "3",
                title: "印章管理",
                subs: [
                    {
                        index: "/yinzhang",
                        title: "印章领用查询",
                    }
                ],
            },
        ];
        const route = useRoute();
        const onRoutes = computed(() => {
            return route.path;
        });

        const sidebar = useSidebarStore();
        const a=sessionStorage.getItem("user_type");
        console.log(a);
        if(a==='1'){
         items.value=item1
        }else if(a==='2'){
        items.value=item2
        }else if(a==='3'){
            items.value=item3
        }else if(a==='4'){
            items.value=item4
        }else{
            router.push("/login");
        }
            
        
        
        return {
            items,
            onRoutes,
            sidebar,
            item1
        };
    },
};
</script>

<style scoped>
.sidebar {
    display: block;
    position: absolute;
    left: 0;
    top: 70px;
    bottom: 0;
    overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
    width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
    width: 250px;
}
.sidebar > ul {
    height: 100%;
}
.sidebar >>> .el-menu-item:hover {
  background: #343b46 !important;
  color: #fff !important;
}
.sidebar >>> .el-menu-item.is-active {
  background: #3779e9 !important;
  color: #fff !important;
}

</style>
